.app__footer {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.app__footer .valid {
  margin-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1em;
  font-size: 2rem;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 768px) {
  .app__footer .valid {
    font-size: 1.2em;
  }
}

.app__footer-cards {
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 4rem 2rem 2rem;
}

.app__footer-cards .app__footer-card {
  min-width: 290px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f2f7fb;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.app__footer-cards .app__footer-card svg {
  width: 40px;
  height: 40px;
  margin-right: 1rem;
  color: var(--secondary-color);
}

.app__footer-cards .app__footer-card p {
  font-weight: 600;
}

.app__footer-cards .app__footer-card a {
  font-weight: 500;
  text-decoration: none;
}

.app__footer-cards .app__footer-card:hover {
  -webkit-box-shadow: 0 0 25px #fef4f5;
          box-shadow: 0 0 25px #fef4f5;
}

@media screen and (max-width: 450px) {
  .app__footer-cards .app__footer-card {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .app__footer-cards .app__footer-card {
    width: 100%;
  }
}

.app__footer-form {
  width: 60%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 1rem 2rem;
}

.app__footer-form div {
  width: 100%;
  margin: 0.75rem 0;
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--primary-color);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.app__footer-form div input,
.app__footer-form div textarea {
  width: 100%;
  padding: 0.95rem;
  border: none;
  border-radius: 7px;
  background-color: var(--primary-color);
  font-family: var(--font-base);
  color: var(--secondary-color);
  outline: none;
}

.app__footer-form div textarea {
  height: 170px;
}

.app__footer-form div:hover {
  -webkit-box-shadow: 0 0 25px var(--primary-color);
          box-shadow: 0 0 25px var(--primary-color);
}

.app__footer-form button {
  padding: 1rem 2rem;
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: var(--secondary-color);
  color: var(--white-color);
  font-weight: 500;
  font-size: 1em;
  outline: none;
  margin: 2rem 0 0 0;
  font-family: var(--font-base);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.app__footer-form button:hover {
  background-color: var(--white-color);
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

@media screen and (max-width: 768px) {
  .app__footer-form {
    width: 100%;
    margin: 1rem 0;
  }
}
