.app {
  background-color: var(--primary-color);
  font-family: var(--font-base);
}

.app__whitebg {
  background-color: var(--white-color);
}

.app__primarybg {
  background-color: var(--primary-color);
}

.app__container {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.app__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.app__wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 4rem 2rem;
}

@media screen and (max-width: 450px) {
  .app__wrapper {
    padding: 4rem 1rem 2rem;
  }
}

.copyright {
  width: 100%;
  padding: 2rem 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.copyright p {
  text-transform: uppercase;
  color: var(--black-color);
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--black-color);
}

.head-text span {
  color: var(--secondary-color);
}

@media screen and (min-width: 2000px) {
  .head-text {
    font-size: 4rem;
  }
}

@media screen and (max-width: 450px) {
  .head-text {
    font-size: 2rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;
}

@media screen and (min-width: 2000px) {
  .p-text {
    font-size: 1.75rem;
  }
}

.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: var(--black-color);
  text-align: left;
}

@media screen and (min-width: 2000px) {
  .bold-text {
    font-size: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .bold-text {
    font-size: 0.9rem;
  }
}

.app__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 1rem;
}

.app__social div {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--white-color);
  margin: 0.25rem 0;
  border: 1px solid var(--lightGray-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.app__social div svg {
  width: 15px;
  height: 15px;
  color: var(--gray-color);
}

.app__social div:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.app__social div:hover svg {
  color: var(--white-color);
}

@media screen and (min-width: 2000px) {
  .app__social div {
    width: 70px;
    height: 70px;
    margin: 0.5rem 0;
  }
  .app__social div svg {
    width: 30px;
    height: 30px;
  }
}

.app__navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 1rem;
}

.app__navigation .app__navigation-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #cbcbcb;
  margin: 0.5rem;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

.app__navigation .app__navigation-dot:hover {
  background-color: var(--secondary-color);
}

@media screen and (min-width: 2000px) {
  .app__navigation .app__navigation-dot {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 500px) {
  .app__navigation,
  .app__social {
    display: none;
  }
  .copyright {
    padding: 2rem;
  }
}
